/* global font styles */
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@200..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Serif SC", serif;
    /* forbid mobile tap highlight backgroud color */
    -webkit-tap-highlight-color: transparent;
    /* define global variables */
    --pretty-base-color: #222;
    --pretty-base-color-light: #666;
    --pretty-base-color-lighter: #aaa;
    --pretty-base-color-dark: #111;
    --pretty-alert-color: #ddd;
    --pretty-bg-color: #fff;
    --pretty-bg-color-lighter: rgba(255, 255, 255, 0.3);
    --pretty-backdrop-filter: blur(10px);
    /* #eee with 0.95 opacity */
    --pretty-nav-bg-color-white: rgba(238, 238, 238, 0.95);
    --pretty-header-bg-color-whiter: rgba(238, 238, 238, 0.3);
    --pretty-footer-bg-color-dark: rgba(2, 2, 2, 0.7);
    --pretty-btn-color: #eee;
    --pretty-btn-color-hover: #ccc;
    --pretty-btn-text-color: #fff;
    --pretty-error-color: #dd5746;
    --pretty-input-placeholder-color: #ccc;
    --pretty-input-border-color: #e5e5e5;
    --pretty-input-border-focus-color: #aaa;
    --pretty-input-bg-color: #e6e6e6;
    --pretty-font-weight: 500;
    --pretty-font-weight-bold: 700;
    --pretty-border-radius: 10px;
    --pretty-border-radius-small: 5px;
    --pretty-border-radius-large: 20px;
    --pretty-transition: all 0.3s ease-in-out;
    --pretty-transition-slow: all 0.5s ease-in-out;
    --pretty-transition-fast: all 0.2s ease-in-out;
    --pretty-inner-container-width: 53rem;
    --top-nav-height: 5rem;
    --top-nav-height-scrolled: 4rem;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: rgb(255,192,203);
    background: linear-gradient(90deg, rgba(255,192,203,1) 0%, rgba(253,192,255,1) 100%); */
    background-color: var(--pretty-bg-color);
}

.App {
    /* text-align: center; */
    text-align: left;
}

.container {
    margin-top: var(--top-nav-height);
    height: calc(100vh - var(--top-nav-height));
    min-height: calc(100vh - var(--top-nav-height));
}

/* footer */
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1rem;
    color: var(--pretty-base-color-lighter);

    & a {
        margin-top: 0.5rem;
        color: var(--pretty-base-color-light);
        text-decoration: none;
        font-weight: var(--pretty-font-weight);
    }
}

/* loading pulse animation */
@-webkit-keyframes pulse {
    50% {
        background: var(--pretty-base-color);
    }
}

@keyframes pulse {
    50% {
        background: var(--pretty-base-color);
    }
}

.loading-pulse {
    position: relative;
    width: 0.75rem;
    height: 2rem;
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: pulse 750ms infinite;
    animation: pulse 750ms infinite;
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;
    border-radius: var(--pretty-border-radius-small);
}

.loading-pulse:before,
.loading-pulse:after {
    content: "";
    position: absolute;
    display: block;
    height: 2rem;
    width: 0.75rem;
    background: rgba(255, 255, 255, 0.2);
    top: 50%;
    transform: translateY(-50%);
    -webkit-animation: pulse 750ms infinite;
    animation: pulse 750ms infinite;
    border-radius: var(--pretty-border-radius-small);
}

.loading-pulse:before {
    left: -1.25rem;
}

.loading-pulse:after {
    left: 1.25rem;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}

/* fade in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* fade out animation */
@keyframes flexFadeOut {
    0% {
        display: flex;
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes gridFadeOut {
    0% {
        display: grid;
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* fade in out animation */
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}