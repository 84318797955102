.blog-delete-modal-btn-group {
    margin-top: 1rem;
    width: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .delete-blog-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;

        & .loading-pulse {
            height: 1.25rem;
        }

        & .loading-pulse::before, 
        .loading-pulse::after {
            height: 1.25rem;
        }
    }

    & button {
        width: 40%;
        padding: 0.5rem;
        font-size: 1rem;
        font-weight: var(--pretty-font-weight);
        border-radius: var(--pretty-border-radius-small);
        border: 2px outset var(--pretty-btn-color);
        color: var(--pretty-base-color);
        background-color: var(--pretty-btn-color);
        letter-spacing: 0.05rem;
        cursor: pointer;
        transition: var(--pretty-transition);

        &:active {
            border-style: inset;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    & .delete-btn {
        width: 55%;
        background-color: var(--pretty-error-color);
        border-color: var(--pretty-error-color);
    }
}

.blog-container {
    text-align: center;
    width: var(--pretty-inner-container-width);
    margin: auto;
    height: 100%;
    font-size: 1rem;
    transition: var(--pretty-transition);
}
.blog-container h1 {
    /* font-size: 2rem; */
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 1rem 0rem;
    user-select: none;
}

.blog-list-loading-container {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    padding: 1rem;
    font-size: 1rem;
    font-weight: var(--pretty-font-weight);
    color: var(--pretty-base-color-light);
    letter-spacing: 0.1rem;
    user-select: none;

    & button {
        height: 3rem;
        color: var(--pretty-base-color);
        font-size: 1rem;
        font-weight: var(--pretty-font-weight-bold);
        letter-spacing: 0.1rem;
        padding: 0rem 0.75rem;
        border-radius: var(--pretty-border-radius-small);
        border: 2px solid var(--pretty-base-color);
        background-color: var(--pretty-bg-color);
        transition: var(--pretty-transition);
        cursor: pointer;

        &:hover {
            color: var(--pretty-bg-color);
            background-color: var(--pretty-base-color);
        }
    }
}

.blog-preview {
    cursor: pointer;
    text-align: start;
    padding: 1.5rem 1.5rem;
    margin: 0.75rem 0;
    /* box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px; */
    border-radius: var(--pretty-border-radius);
    user-select: none;
    text-decoration: none;
    word-break: break-all;
    color: var(--pretty-base-color);
    transition: var(--pretty-transition);
}
.blog-preview:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.blog-preview h1,
.blog-preview p,
.blog-preview text {
    overflow-wrap: break-word;
}

.blog-preview .blog-preview-horizontal-container {
    height: auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.blog-preview .blog-preview-horizontal-container img {
    object-fit: contain;
    margin: auto;
    margin-left: 0.5rem;
    display: block;
    border-radius: var(--pretty-border-radius-small);
    /* max-height set to the sum of the heights of the title, description, and category */
    max-height: calc(7rem + 3.7rem + 1.25rem);
    max-width: 25rem;
    transition: var(--pretty-transition);
}

.blog-preview .blog-preview-horizontal-container .blog-preview-info-container {
    flex-grow: 1;
}

.blog-preview .blog-preview-horizontal-container .blog-preview-info-container .blog-preview-category {
    margin-left: 0.1rem;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: var(--pretty-base-color-lighter);
}

.blog-preview .blog-preview-horizontal-container .blog-preview-info-container .blog-preview-title {
    /* limit the number of lines and truncate the text with ellipses, set max-height to 7rem for two lines of title */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 7rem;
    margin: 0;
    padding: 0.75rem 0rem;
    font-size: 2rem;
}

.blog-preview .blog-preview-horizontal-container .blog-preview-info-container .blog-preview-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3.7rem;
    max-width: fit-content;
    color: var(--pretty-base-color-light);
    /* background-color: var(--pretty-btn-color); */
    border-radius: var(--pretty-border-radius-small);
    padding: 0.5rem 0rem;
    font-size: 1rem;
}
.blog-preview .blog-preview-create {
    font-size: 1rem;
    max-width: fit-content;
    color: var(--pretty-base-color-lighter);
}

@media screen and (max-width: 1024px) {
    .blog-container {
        width: 100%;
        padding: 0% 10%;
    }
}

@media screen and (max-width: 800px) {
    .blog-container {
        width: 100%;
        padding: 0% 2.5%;
    }

    /* no hover effect on pad */
    .blog-preview:hover {
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        transition: var(--pretty-transition);
    }

    .blog-preview .blog-preview-horizontal-container img {
        max-width: 40%;
        max-height: 9rem;
    }
}

@media screen and (max-width: 450px) {
    .blog-container {
        width: 100%;
        padding: 0% 2.5%;
    }

    .blog-preview {
        padding: 1rem 1rem;
    }

    /* no hover effect on mobile */
    .blog-preview:hover {
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        transition: var(--pretty-transition);
    }

    .blog-preview .blog-preview-horizontal-container img {
        max-width: 40%;
        max-height: 9rem;
    }

    .blog-preview .blog-preview-horizontal-container .blog-preview-info-container .blog-preview-title {
        font-size: 1.25rem;
        max-height: 4.75rem;
    }

    .blog-preview .blog-preview-horizontal-container .blog-preview-info-container .blog-preview-description {
        font-size: 0.8rem;
        max-height: 3.1rem;
    }
    
    .blog-preview .blog-preview-create {
        font-size: 0.8rem;
    }
}