.customize-dish-menu-modal-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--pretty-bg-color);

    & form {
        display: flex;
        flex-direction: column;
    }

    & textarea {
        resize: none;
        width: 25rem;
        height: 15rem;
        font-size: 1rem;
        padding: 0.5rem;
        border: none;
        border-radius: var(--pretty-border-radius-small);
        border: 2px solid var(--pretty-input-border-color);
        transition: var(--pretty-transition);
    }

    & textarea:focus {
        outline: none;
        border-color: var(--pretty-input-border-color-focus);
    }

    @media screen and (max-width: 800px) {
        & textarea {
            width: 20rem;
        }
    }

    @media screen and (max-width: 450px) {
        & textarea {
            width: 15rem;
        }
    }

    & .horizontal-btn-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        & button:nth-child(1) {
            flex-grow: 1;
        }
    }

    & button {
        user-select: none;
        width: auto;
        font-size: 1rem;
        font-weight: var(--pretty-font-weight-bold);
        letter-spacing: 0.1rem;
        margin-top: 1rem;
        padding: 0.5rem;
        border-radius: var(--pretty-border-radius-small);
        border: 2px solid var(--pretty-base-color);
        cursor: pointer;
        color: var(--pretty-base-color);
        background-color: var(--pretty-bg-color);
        transition: var(--pretty-transition);

        &:hover {
            color: var(--pretty-bg-color);
            background-color: var(--pretty-base-color);
        }
    }

    & .customize-input-submitting-container {
        display: flex;
        justify-content: center;
        align-items: center;

        & .loading-pulse {
            height: 1.25rem;
        }

        & .loading-pulse::before,
        .loading-pulse::after {
            height: 1.25rem;
        }
    }
}

.meal-recipe-generator-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: break-word;
    padding-bottom: var(--top-nav-height);

    & .info-container {
        z-index: 2;
        width: auto;
        height: auto;
        background: var(--pretty-bg-color-lighter);
        backdrop-filter: var(--pretty-backdrop-filter);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--pretty-border-radius-small);

        & .current-text-container {
            min-width: 10rem;
            color: var(--pretty-base-color);
            text-align: center;
            text-decoration: none;
            font-size: 2rem;
            font-weight: bold;
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            border-radius: var(--pretty-border-radius-small);
            cursor: pointer;

            &:hover {
                &::after {
                    content: "↗";
                    font-size: 0.75rem;
                    position: absolute;
                    top: 0.5rem;
                }
            }
        }

        & .start-stop-button {
            user-select: none;
            width: 5rem;
            color: var(--pretty-base-color);
            background-color: var(--pretty-bg-color-lighter);
            font-size: 1rem;
            font-weight: var(--pretty-font-weight-bold);
            padding: 0.5rem;
            letter-spacing: 0.1rem;
            border-radius: var(--pretty-border-radius-small);
            border: 2px solid var(--pretty-base-color);
            transition: var(--pretty-transition);
            cursor: pointer;

            &:hover {
                color: var(--pretty-bg-color);
                background-color: var(--pretty-base-color);
            }
        }

        & .customize-menu-button {
            user-select: none;
            font-size: 0.9rem;
            padding: 0.5rem;
            color: var(--pretty-base-color-light);
            text-align: center;
            cursor: pointer;
        }
    }

    & .floating-text {
        z-index: 1;
        font-weight: var(--pretty-font-weight);
        position: fixed;
        animation: fadeInOut 1s forwards;
    }
}