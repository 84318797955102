.blog-detail-container {
    text-align: start;
    width: var(--pretty-inner-container-width);
    margin: auto;
    height: 100%;
    font-size: 1rem;
}

.blog-detail-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    padding: 1rem;
}

.blog-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* for blog header */
.blog-detail-header {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: var(--pretty-header-bg-color-whiter); */
    /* border-radius: var(--pretty-border-radius-large); */
    border-bottom: 2px solid var(--pretty-nav-bg-color-white);
}

.blog-detail-container .blog-detail-link {
    text-decoration: none;
    color: var(--pretty-base-color-lighter);
}

.blog-detail-container h1,
.blog-detail-container p,
.blog-detail-container text {
    overflow-wrap: break-word;
}

.blog-detail-container .blog-detail-title {
    text-align: center;
    font-size: 2rem;
    color: var(--pretty-base-color-light);
}

.blog-detail-container .blog-detail-category {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    color: var(--pretty-base-color-lighter);
    margin-top: 1rem;
}

.blog-detail-container .blog-detail-decription {
    text-align: center;
    max-width: fit-content;
    color: var(--pretty-base-color-light);
    /* background-color: var(--pretty-btn-color); */
    border-radius: var(--pretty-border-radius-small);
    padding: 0.5rem 0rem;
    margin-top: 0.5rem;
}

.blog-detail-container .blog-detail-create {
    text-align: center;
    font-size: 1rem;
    font-weight: var(--pretty-font-weight);
    max-width: fit-content;
    color: var(--pretty-base-color-lighter);
    border-radius: var(--pretty-border-radius-small);
    padding: 0.5rem;
    letter-spacing: 0.1rem;
}

.blog-detail-footer {
    background-color: var(--pretty-footer-bg-color-dark);
    color: var(--pretty-btn-text-color);
    padding: 1rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: fit-content;
    border-radius: var(--pretty-border-radius);
}

.blog-detail-container .blog-detail-about-title {
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
}

@media screen and (max-width: 1024px) {
    .blog-detail-container {
        padding: 0% 10%;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .blog-detail-container {
        width: 100%;
        padding: 0% 2.5%;
    }
}