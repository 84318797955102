.full-modal {
    /* opacity: 0;
    visibility: hidden; */
    user-select: none;
    z-index: 9999;
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: var(--pretty-transition);
    animation: flexFadeOut 0.3s ease;

    &.open {
        /* opacity: 1;
        visibility: visible; */
        display: flex;
        animation: fadeIn 0.3s ease;
    }
    
    & .full-modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
    }

    & .full-modal-content {
        background-color: var(--pretty-bg-color);
        padding: 1.5rem 1.5rem;
        border-radius: var(--pretty-border-radius);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    & button {
        margin-top: 0.5rem;
    }

    & .full-modal-close {
        position: absolute;
        top: 0.25rem;
        right: 0.5rem;
        cursor: pointer;
        font-size: 24px;
        font-weight: var(--pretty-font-weight);
    }
}