.tiptap-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tiptap {
    width: 100%;
    min-height: 30vh;
    padding: 0.75rem 0.75rem;
    margin-top: 0.25rem;
    /* padding: 0.1rem 0.5rem; */
    border: 2px solid var(--pretty-input-border-color);
    border-radius: var(--pretty-border-radius-small);
    transition: var(--pretty-transition);

    /* applies a margin of 0.5em to the top of each direct child element of elements with the class ".tiptap" that immediately follow another element */
    >*+* {
        margin-top: 0.5rem;
    }

    /* set placeholder */
    p.is-editor-empty:first-child::before {
        color: rgb(117, 117, 117);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    & h1,
    & h2,
    & h3,
    & h4 {
        /* line-height: normal; */
        width: auto;
    }

    & code {
        background-color: var(--pretty-nav-bg-color-white);
        color: var(--pretty-base-color-light);
    }

    & pre {
        background-color: var(--pretty-base-color);
        color: var(--pretty-btn-text-color);
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        & code {
            color: inherit;
            background: none;
            padding: 0;
            font-size: 0.9rem;
        }
    }

    & ul,
    & ol {
        padding: 0rem 1rem;
    }

    & blockquote {
        margin-left: 1rem;
        padding-left: 1rem;
        border-left: 2px solid var(--pretty-nav-bg-color-white);
    }

    & hr {
        border: none;
        border-top: 2px solid var(--pretty-nav-bg-color-white);
        margin: 2rem 0;
    }

    /* for highlighting text */
    & mark {
        border-radius: var(--pretty-border-radius-small);
        padding: 0.125rem;
    }

    .tiptap-image {
        display: block;
        border-radius: var(--pretty-border-radius);
        max-height: 30rem;
        max-width: 100%;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
}

.tiptap:focus {
    outline: none;
    border-color: var(--pretty-input-border-focus-color);
}


.tiptap-error {
    border: 2px solid var(--pretty-error-color);
}

/* style when setting editable as false */
[contenteditable="false"] {
    cursor: not-allowed;
    border: none;
}

.floating-menu-container {
    background-color: var(--pretty-nav-bg-color-white);
    border-radius: var(--pretty-border-radius-small);

    & button {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.25rem 0.25rem;
        font-size: 1rem;
        background-color: var(--pretty-btn-text-color);
        /* color: var(--pretty-base-color-light); */
        border: none;
        border-radius: var(--pretty-border-radius-small);
        cursor: pointer;
        transition: var(--pretty-transition);

        &:hover {
            background-color: var(--pretty-nav-bg-color-white);
        }

        &.active {
            background-color: var(--pretty-nav-bg-color-white);
        }
    }
}