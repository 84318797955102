.blog-create-btn {
    z-index: 9;
    position: fixed;
    bottom: 4%;
    right: 4%;
    /* padding: 0.5rem 0.7rem; */
    width: 3.5rem;
    height: 3.5rem;
    /* keep the icon in the center */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--pretty-border-radius);
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    opacity: 0.3;
    background-color: var(--pretty-base-color);
    color: var(--pretty-btn-color);
    transition: var(--pretty-transition);
    cursor: pointer;
}
.blog-create-btn:hover {
    opacity: 1;
    transition: var(--pretty-transition);
}

.blogs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}