.reset-password-modal-email-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--pretty-bg-color);

    & form {
        display: flex;
        flex-direction: column;
    }
    
    & input {
        width: 15rem;
        font-size: 1rem;
        margin: 0.5rem;
        padding: 0.5rem;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid var(--pretty-input-border-color);
        transition: var(--pretty-transition);
    }

    & input:focus {
        outline: none;
        border-color: var(--pretty-input-border-color-focus);
    }

    & .forgot-password-email-error-message {
        align-self: flex-end;
        font-size: 0.75rem;
        color: var(--pretty-error-color);
        margin-right: 0.5rem;
    }

    & button {
        user-select: none;
        height: 2.75rem;
        font-size: 1rem;
        font-weight: var(--pretty-font-weight);
        margin-top: 1rem;
        padding: 0 0.5rem;
        border-radius: var(--pretty-border-radius-small);
        border: 2px solid var(--pretty-base-color);
        cursor: pointer;
        color: var(--pretty-base-color);
        background-color: var(--pretty-bg-color);
        transition: var(--pretty-transition);

        &:hover {
            color: var(--pretty-bg-color);
            background-color: var(--pretty-base-color);
        }
    }

    .reset-password-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.75rem;
        margin-top: 1rem;

        & .loading-pulse {
            height: 1.25rem;
        }

        & .loading-pulse::before, 
        .loading-pulse::after {
            height: 1.25rem;
        }
    }
}

/* for mobile */
@media screen and (max-width: 450px) {
    .reset-password-modal-email-container {
        & input {
            width: 16rem;
        }
    }
}

@media screen and (max-width: 375px) {
    .reset-password-modal-email-container {
        & input {
            width: 14rem;
        }
    }
}


/* login block */
.login-page-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;
}

.login-container {
    display: block;
    margin: auto;
    position: relative;
    width: min(90%, 960px);
    height: min(90%, 600px);
    min-width: 225px;
    min-height: 500px;
    background-color: var(--pretty-bg-color);
    /* box-shadow: 0 0 1em #e5e5e5; */
    font-family: inherit;
    border-radius: var(--pretty-border-radius);
}

.login-main-container,
.login-aside-container {
    position: absolute;
    top: 0;
    height: 100%;
    transform: translateX(0);
    transition: all 1s ease-in-out;
    border-radius: var(--pretty-border-radius);
}

.login-main-container {
    left: 0;
    width: 60%;
}

.login-aside-container {
    right: 0;
    width: 40%;
    background-color: var(--pretty-base-color);
    opacity: 0.8;
}

.login-form-block {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-form-block h2 {
    user-select: none;
    color: var(--pretty-base-color);
    font-size: 2.5rem;
    margin: 0em 3rem 1rem 3rem;
}

.login-form-block form {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.login-form-input-container {
    /* margin-bottom: 1em; */
    height: calc(1.25rem + 0.5rem + 3em + 0.5rem + 0.75rem);
    display: flex;
    flex-direction: column;
}

.login-form-input-container label {
    font-size: 1rem;
    height: 1.25rem;
}

.login-form-input-container .login-form-input {
    margin-top: 0.5em;
    width: 100%;
    height: 3em;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid var(--pretty-input-border-color);
    background-color: var(--pretty-input-bg-color);
    padding-left: 5%;
}

.login-form-input-container .login-form-input-error {
    border: 2px solid var(--pretty-error-color);
}

.login-form-input-container .login-form-input-error-message {
    align-self: flex-end;
    font-size: 0.75rem;
    height: 0.75rem;
    color: var(--pretty-error-color);
}

.login-form-input-container .password-toggle-button-signin {
    position: absolute;
    top: calc(50% + 0.75rem);
    right: 15%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    color: var(--pretty-base-color);
    cursor: pointer;
}

.login-form-input-container .password-toggle-button-signup {
    position: absolute;
    top: calc(50% + 4.5rem);
    right: 15%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    color: var(--pretty-base-color);
    cursor: pointer;
}

.login-form-block button {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    transition: var(--pretty-transition);
    padding: 1em 2em;
    border-radius: var(--pretty-border-radius-large);
    background-color: var(--pretty-base-color);
    color: var(--pretty-btn-text-color);
    cursor: pointer;
}

/* overrides loading pulse height to fit for the submit button */
.login-form-block .loading-pulse {
    height: 1.75rem;
}

.login-form-block .loading-pulse::before, 
.login-form-block .loading-pulse::after {
    height: 1.75rem;
}

.login-form-block .login-form-forgot-password-link {
    font-size: 0.9rem;
    color: var(--pretty-base-color-light);
    text-align: center;
    cursor: pointer;
}

.login-main-container button {
    border: none;
    margin: 1.5em 0;
    align-self: center;
}

.login-aside-container button {
    border: 2px solid var(--pretty-btn-color);
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: 1.05rem;
    padding: 1em 2.5em;
    border-radius: var(--pretty-border-radius-large);
}

.login-aside-container h2 {
    user-select: none;
    margin: 0rem 3rem;
    font-size: 2rem;
    color: var(--pretty-btn-text-color);
}

/* Aside Section */
.login-aside-container .signin-block,
.login-aside-container .signup-block {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.signin-block button,
.signup-block button {
    background-color: var(--pretty-btn-color);
    color: var(--pretty-base-color);
    cursor: pointer;
}

/* initially show signup form and signin block */
/* .login-main-container .signup-block,
.login-aside-container .#signInForm {
    display: none;
} */

/* initially show signin form and signup block */
.login-aside-container .signin-block,
.login-main-container #signUpForm {
    display: none;
}

/* Additional Classes */

.login-main-container.slide-right {
    /* left: 100%; */
    transform: translateX(calc(100%*2/3));
}

.login-aside-container.slide-left {
    /* right: 100%; */
    transform: translateX(calc(-100%*3/2));
}

/* while sliding */
.login-main-container.slide-right #signInForm,
.login-aside-container.slide-left .signup-block {
    display: none;
}

.login-main-container.slide-right #signUpForm,
.login-aside-container.slide-left .signin-block {
    display: flex;
}

.login-form-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* adapt to mobile devices */
@media (max-width: 800px) {
    .login-page-container {
        margin-top: 5rem;
        padding-bottom: 3rem;
    }

    .login-container {
        min-height: 500px;
        display: block;
        margin: auto auto;
    }

    .login-main-container,
    .login-aside-container {
        width: 100%;
    }

    .login-main-container {
        height: 75%;
        top: 0;
        left: 0;
    }

    .login-main-container button {
        margin: 0rem;
    }

    .login-form-block h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .login-form-block button {
        margin-top: 0.5rem;
        font-size: 1rem;
    }

    .login-form-input-container {
        height: calc(1rem + 0.5rem + 2.25em + 0.5rem + 0.6rem);
    }

    .login-form-input-container label {
        font-size: 0.85rem;
        height: 1rem;
    }

    .login-form-input-container .login-form-input {
        margin-top: 0.5em;
        font-size: 1rem;
        height: 2.25rem;
    }

    .login-form-input-container .login-form-input-error-message {
        font-size: 0.6rem;
        height: 0.6rem;
    }

    .login-form-input-container .password-toggle-button-signin {
        top: calc(50% + 1.3rem);
    }

    .login-form-input-container .password-toggle-button-signup {
        top: calc(50% + 4.5rem);
    }

    .login-form-block .loading-pulse,
    .login-form-block .loading-pulse::before,
    .login-form-block .loading-pulse::after {
        height: 1.5rem;
    }

    .login-form-block .login-form-forgot-password-link {
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }

    .login-aside-container {
        top: auto;
        bottom: 0;
        left: 0;
        height: 25%;
    }

    .login-aside-container h2 {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
    }

    .login-aside-container button {
        font-size: 0.85rem;
        padding: 0.75em 2em;
    }

    /* animation classes */

    .login-main-container.slide-right {
        transform: translate(0, calc(100%*1/3));
    }

    .login-aside-container.slide-left {
        transform: translate(0, calc(-100%*3/1));
    }
}