.widgets-container {
    text-align: center;
    width: var(--pretty-inner-container-width);
    margin: auto;
    height: auto;
    font-size: 1rem;
    transition: var(--pretty-transition);

    & h1 {
        /* font-size: 2rem; */
        font-weight: bold;
        margin-bottom: 1rem;
        padding: 1rem 0rem;
        user-select: none;
    }

    & .widget-grid-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(8rem, auto);

        & .widget-grid-item {
            margin: 0.5rem;
            padding: 1rem;
            border-radius: 0.5rem;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            & .widget-grid-item-title {
                /* limit the number of lines and truncate the text with ellipses, set max-height to 7rem for two lines of title */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 5.5rem;
                margin: 0;
                padding: 0.75rem 0rem;
                font-size: 1.5rem;
                overflow-wrap: break-word;
            }

            & .widget-grid-item-description {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 3.7rem;
                max-width: fit-content;
                color: var(--pretty-base-color-light);
                /* background-color: var(--pretty-btn-color); */
                border-radius: var(--pretty-border-radius-small);
                padding: 0.5rem 0rem;
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .widgets-container {
        width: 100%;
        padding: 0% 10%;

        & .widget-grid-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

@media screen and (max-width: 800px) {
    .widgets-container {
        width: 100%;
        padding: 0% 5%;

        & .widget-grid-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

@media screen and (max-width: 450px) {
    .widgets-container {
        width: 100%;
        padding: 0% 2.5%;

        & .widget-grid-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}