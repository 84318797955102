.alert-modal {
    font-weight: var(--pretty-font-weight);
    user-select: none;
    z-index: 9999;
    display: none;
    justify-content: center;
    /* align-items: center; */
    position: fixed;
    top: var(--top-nav-height);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 25rem;
    height: auto;
    border-radius: var(--pretty-border-radius);
    /* background-color: rgba(0, 0, 0, 0.5); */
    transition: var(--pretty-transition);
    /* opacity: 0;
    visibility: hidden; */
    animation: flexFadeOut 0.3s ease;

    &.open {
        /* opacity: 1;
        visibility: visible; */
        display: flex;
        animation: fadeIn 0.3s ease;
    }

    & .alert-modal-content {
        background-color: var(--pretty-alert-color);
        padding: 1.5rem;
        padding-right: 2rem;
        border-radius: var(--pretty-border-radius);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    & button {
        margin-top: 0.5rem;
    }

    & .alert-modal-close {
        position: absolute;
        right: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
    }
}