.comment-container {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;

    & .comment-title {
        user-select: none;
        padding: 0.5rem 0;
        font-size: 1.5rem;
    }

    & .comment-tip {
        width: fit-content;
        font-size: 1rem;
        padding: 0.5rem;
        border-radius: var(--pretty-border-radius-small);
        background-color: var(--pretty-alert-color);
    }

    & .comment-item {
        display: flex;
        flex-direction: column;
        border-radius: var(--pretty-border-radius-small);
        padding: 1rem 0rem;

        & .comment-item-horizontal-group {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding-bottom: 0.5rem;
            justify-content: space-between;

            & .comment-item-horizontal-group-left {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                gap: 0.75rem;

                & .comment-item-name {
                    font-weight: 700;
                    font-size: 1rem;
                    /* prevent text from turning to a new line */
                    white-space: nowrap;
                }

                & .comment-item-date {
                    font-size: 0.8rem;
                    color: var(--pretty-base-color-lighter);
                    /* hide text if it's too long */
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            & .comment-item-horizontal-group-right {
                /* align-self: flex-end; */
                display: inline-flex;
                flex-direction: row;
                gap: 0.5rem;
                align-items: center;
                
                & [class^="ri-"] {
                    font-size: 1.25rem;
                    transition: var(--pretty-transition);
                    cursor: pointer;
                }
            }
        }
    }

    & .comment-reply-item {
        display: flex;
        flex-direction: column;
        border-radius: var(--pretty-border-radius-small);
        padding: 0.5rem 0rem 0rem 1rem;

        & .comment-reply-item-horizontal-group {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding-bottom: 0.5rem;
            justify-content: space-between;

            & .comment-reply-item-horizontal-group-left {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                gap: 0.75rem;

                & .comment-reply-item-name {
                    font-weight: 700;
                    font-size: 1rem;
                    /* prevent text from turning to a new line */
                    white-space: nowrap;
                }

                & .comment-reply-item-date {
                    font-size: 0.8rem;
                    color: var(--pretty-base-color-lighter);
                    /* hide text if it's too long */
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & .comment-reply-item-to {
                    font-weight: 700;
                    font-size: 0.8rem;
                    color: var(--pretty-base-color-lighter);
                    /* hide text if it's too long */
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            & .comment-reply-item-horizontal-group-right {
                /* align-self: flex-end; */
                display: inline-flex;
                flex-direction: row;
                gap: 0.5rem;
                align-items: center;
                
                & [class^="ri-"] {
                    font-size: 1.25rem;
                    transition: var(--pretty-transition);
                    cursor: pointer;
                }
            }
        }
    }

    & .comment-item-expand {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding-top: 0.5rem;
        padding-left: 1rem;
        font-size: 0.8rem;
        user-select: none;
        cursor: pointer;
    }

    & .comment-list-loading-container {
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    & .reply-list-loading-container {
        height: 2.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0.5rem 0.5rem 2.25rem;

        & .loading-pulse {
            height: 0.75rem;

            &::before,
            &::after {
                height: 0.75rem;
            }
        }
    }
}