.NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 2rem;
    background: var(--pretty-bg-color-lighter);
    backdrop-filter: var(--pretty-backdrop-filter);
    /* box-shadow: 0 5px 15px rgba(34, 34, 34, 0.25); */
    width: 100%;
    z-index: 99;
    /* fix the nv bar at the top */
    position: fixed;
    top: 0;
    transition: var(--pretty-transition);
    height: var(--top-nav-height);
    /* opacity: 0.75; */
}

.NavbarItems-affix {
    height: var(--top-nav-height-scrolled);
    /* background: var(--pretty-nav-bg-color-white); */
    transition: var(--pretty-transition);
}

.bounden-title {
    padding-left: 3rem;
    color: var(--pretty-base-color);
    cursor: pointer;
    /* ensure logo and text are in the same line */
    transition: var(--pretty-transition);
}

.bounden-title .bounden-title-text {
    font-size: 1.5rem;
    font-weight: 800;
}

.bounden-title .nav-link-home {
    text-decoration: none;
    color: var(--pretty-base-color);
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.bounden-title .bounden-icon {
    height: 2rem;
    margin-right: 0.5rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    /* nav bar element spacing */
    grid-gap: 0.5rem;
    /* remove bullet points */
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
    /* space for user icon */
    margin-right: 5rem;
    z-index: 9;
}

/* change color of active link */
.nav-links {
    text-decoration: none;
    color: var(--pretty-base-color-light);
    border-radius: var(--pretty-border-radius);
    padding: 0.7rem 0.5rem;
    white-space: nowrap;
    font-weight: var(--pretty-font-weight);
}

.nav-links i {
    padding-right: 0.5rem;
    font-size: 1.25rem;
}

.nav-links:hover {
    background-color: var(--pretty-btn-color);
    border-radius: var(--pretty-border-radius);
    color: var(--pretty-base-color-dark);
    transition: all 0.2s ease-in-out;
}

/* toggle menu icon */
.menu-icon {
    /* hide by default */
    position: absolute;
    left: 1.5rem;
    display: none;
    justify-content: start;
    font-size: 1.5rem;
    color: var(--pretty-base-color);
    padding-bottom: 0.25rem;
}

.menu-icon:hover {
    /* color: var(--pretty-btn-color-hover); */
    cursor: pointer;
}

.nav-link-user-container {
    position: absolute;
    right: 3rem;
    /* justify-content: end; */
    border-radius: var(--pretty-border-radius);
    border: 2px solid var(--pretty-base-color-light);
    border-style: dashed;
    cursor: pointer;
}

.nav-link-user-container .user-icon {
    margin: 0.1rem 0.3rem 0.15rem 0.3rem;
    white-space: nowrap;
    background-color: transparent;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: var(--pretty-font-weight);
    color: var(--pretty-base-color-light);
    transition: var(--pretty-transition);
}

.nav-link-user-container:hover {
    background-color: var(--pretty-btn-color);
    transition: var(--pretty-transition);
}

.nav-link-user-container.active {
    border-style: inset;
    color: var(--pretty-base-color);
    transition: var(--pretty-transition);
}

.nav-link-user-container .nav-link-user-container .nav-link-user {
    color: var(--pretty-base-color-light);
    text-decoration: none;
}

.nav-link-user-container .dropdown-list {
    width: auto;
    position: absolute;
    top: 110%;
    right: 0;
    margin-top: 0.5rem;
    padding: 0.25rem;
    background-color: var(--pretty-btn-text-color);
    border: 1px solid var(--pretty-input-border-color);
    border-radius: var(--pretty-border-radius-small);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: none;
    flex-direction: column;
    z-index: 1;
    transition: var(--pretty-transition);
    user-select: none;
    animation: flexFadeOut 0.3s ease;

    &.active {
        display: flex;
        animation: fadeIn 0.3s ease;
    }

    & .dropdown-item {
        padding: 0.5rem 0.25rem;
        border-radius: var(--pretty-border-radius-small);
        cursor: pointer;
        transition: var(--pretty-transition);

        &:hover {
            background-color: var(--pretty-nav-bg-color-white);
        }

        &.active {
            background-color: var(--pretty-nav-bg-color-white);
        }
    }
}

/* change navbar style on small screens */
@media screen and (max-width: 800px) {
    .NavbarItems {
        justify-content: center;
    }

    .bounden-title {
        padding-left: 0rem;
    }
    
    .bounden-icon {
        display: none;
    }

    .nav-cover {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 8;
        visibility: hidden;
        opacity: 0;
        transition: var(--pretty-transition-slow);

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* box-shadow: 0 5px 15px rgba(34, 34, 34, 0.4); */
        background-color: var(--pretty-bg-color);
        /* add blur effect */
        /* backdrop-filter: blur(10px); */
        position: absolute;
        top: 0px;
        /* left: -18rem; */
        left: -18rem;
        width: 16rem;
        height: 100vh;
        align-items: stretch;
        padding: 100px 0 30px 0;
        margin: 0;
        z-index: 9;
        transition: var(--pretty-transition-slow);
    }

    .nav-menu.active {
        transform: translateX(18rem);
    }

    .nav-links {
        display: inline-block;
        widows: 100%;
        padding: 1.5rem 1.5rem;
        color: var(--pretty-base-color);
        cursor: pointer;
    }

    .nav-links:hover {
        background-color: var(--pretty-btn-color-hover);
        color: var(--pretty-base-color-dark);
    }

    /* hover underline effect for active link */
    .nav-links::before {
        position: absolute;
        content: "";
        width: 10%;
        height: 1.5rem;
        align-content: center;
        margin-top: 0rem;
        right: 0;
        background-color: var(--pretty-base-color);
        transform: scaleX(0);
        transition: var(--pretty-transition);
        transform-origin: right;
    }

    .nav-links:hover::before {
        transform: scaleX(1);
    }

    .nav-link-user-container {
        right: 1.5rem;
    }

    .menu-icon {
        display: block;
        /* align with the user icon */
        margin-top: 0.25rem;
        z-index: 99;
    }
}