/* image modal */
.image-upload-modal-tab-bar {
    margin-top: 1rem;
    width: 100%;
    height: auto;
    border-radius: var(--pretty-border-radius-small);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    -webkit-padding-start: 0;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    & .image-upload-modal-tab-item {
        background-color: var(--pretty-bg-color);
        color: var(--pretty-base-color);
        padding: 0.75rem 0;
        font-weight: var(--pretty-font-weight);
        letter-spacing: 0.1rem;
        margin: auto;
        cursor: pointer;
        user-select: none;
    }

    & .image-upload-modal-indicator {
        position: absolute;
        border-radius: var(--pretty-border-radius-small);
        width: calc((100% - 5rem)/2);
        height: 0.25rem;
        background: var(--pretty-base-color);
        margin-top: 2.75rem;
        transition: var(--pretty-transition);
    }
}
.image-upload-modal-url-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--pretty-bg-color);

    & form {
        display: flex;
        flex-direction: column;
    }
    
    & input {
        width: 20rem;
        font-size: 1rem;
        margin: 0.5rem;
        padding: 0.5rem;
        border: none;
        border-bottom: 2px solid var(--pretty-input-border-color);
        transition: var(--pretty-transition);
    }

    & input:focus {
        outline: none;
        border-color: var(--pretty-input-border-color-focus);
    }

    & button {
        margin: auto;
        width: 7rem;
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: var(--pretty-font-weight);
        letter-spacing: 0.1rem;
        padding: 0.5rem;
        border-radius: var(--pretty-border-radius-small);
        border: 2px outset var(--pretty-btn-color);
        color: var(--pretty-base-color);
        background-color: var(--pretty-btn-color);
        cursor: pointer;
        transition: var(--pretty-transition);

        &:active {
            border-style: inset;
        }
    }
}
.image-upload-modal-container {
    margin-top: 1rem;
    padding: 2rem 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: var(--pretty-border-radius);
    border: 4px dashed var(--pretty-base-color-lighter);
    background-color: var(--pretty-input-border-color);
    position: relative;
    cursor: pointer;
    color: var(--pretty-base-color-lighter);
    transition: var(--pretty-transition);

    & .ri-file-upload-line {
        font-size: 3rem;
        margin: 0.5rem;
    }

    & input {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
    }

    & .image-upload-modal-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 4rem;
        padding: 1rem;
    }
}
.image-upload-modal-container:hover {
    border: 4px dashed var(--pretty-base-color-light);
    color: var(--pretty-base-color-light);
}

/* for mobile */
@media screen and (max-width: 450px) {
    .image-upload-modal-url-container{
        & input {
            width: 16rem;
        }
    }

    .image-upload-modal-container {
        padding: 1rem 2rem;

        & .ri-file-upload-line {
            font-size: 2.5rem;
        }
    }
}

/* tiptap toolbar */
.tiptap-toolbar {
    /* border-bottom: 2px solid var(--pretty-input-border-color); */
    margin-bottom: 0.25rem;
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* set sticky position to the toolbar after user enter bunch of lines in the blog */
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: var(--top-nav-height-scrolled);
    z-index: 1;
    background-color: var(--pretty-bg-color);

    & .tool-block {
        display: block;
    }

    & .dropdown-menu {
        width: auto;
        margin: 0.1rem;
        position: relative;
        user-select: none;

        & .dropdown-btn {
            font-size: 1rem;
            width: auto;
            padding: 0.25rem 0.25rem;
            border: none;
            border-radius: var(--pretty-border-radius-small);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            background-color: var(--pretty-btn-text-color);
            color: var(--pretty-base-color);
            transition: var(--pretty-transition);

            & .dropdown-btn-icon {
                border-radius: var(--pretty-border-radius-small);
            }

            & .dropdown-btn-arrow {
                color: var(--pretty-base-color-light);
            }

            &:hover {
                background-color: var(--pretty-nav-bg-color-white);
            }

            @media screen and (max-width: 800px) {
                &:hover {
                    background-color: var(--pretty-bg-color);
                }
            }

            & .dropdown-btn-horizontal-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 0.5rem;
                font-size: 1.05rem;
                transition: var(--pretty-transition);

                & .dropdown-btn-icon {
                    display: block;
                }

                @media screen and (max-width: 1024px) {
                    /* & .dropdown-btn-icon {
                        display: none;
                    } */

                    & .dropdown-btn-text {
                        display: none;
                    }
                }
            }
        }

        & .dropdown-grid {
            width: auto;
            position: absolute;
            top: 110%;
            left: 0;
            padding: 0.25rem;
            background-color: var(--pretty-btn-text-color);
            border: 1px solid var(--pretty-input-border-color);
            border-radius: var(--pretty-border-radius-small);
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: none;
            z-index: 2;
            grid-template-columns: repeat(5, 1.5rem);
            column-gap: 0.25rem;
            row-gap: 0.5rem;

            &.active {
                display: grid;
                animation: fadeIn 0.3s ease;
            }

            & button {
                margin: auto;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: var(--pretty-border-radius-small);
                cursor: pointer;
                transition: var(--pretty-transition);

                &:hover {
                    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
                }

                &.active {
                    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
                }
            }
        }

        & .dropdown-list {
            width: 150px;
            position: absolute;
            top: 110%;
            left: 0;
            padding: 0.25rem;
            background-color: var(--pretty-btn-text-color);
            border: 1px solid var(--pretty-input-border-color);
            border-radius: var(--pretty-border-radius-small);
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: none;
            flex-direction: column;
            z-index: 2;
            transition: var(--pretty-transition);

            &.active {
                display: flex;
                animation: fadeIn 0.3s ease;
            }

            & .dropdown-item {
                padding: 0.5rem 0.25rem;
                border-radius: var(--pretty-border-radius-small);
                cursor: pointer;
                transition: var(--pretty-transition);

                &:hover {
                    background-color: var(--pretty-nav-bg-color-white);
                }

                &.active {
                    background-color: var(--pretty-nav-bg-color-white);
                }
            }
        }
    }


    & button {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.25rem 0.25rem;
        font-size: 1rem;
        background-color: var(--pretty-btn-text-color);
        color: var(--pretty-base-color);
        /* color: var(--pretty-base-color-light); */
        border: none;
        border-radius: var(--pretty-border-radius-small);
        cursor: pointer;
        transition: var(--pretty-transition);

        &:hover {
            background-color: var(--pretty-nav-bg-color-white);
        }

        @media screen and (max-width: 800px) {
            &:hover {
                background-color: var(--pretty-bg-color);
            }
        }

        &.active {
            background-color: var(--pretty-nav-bg-color-white);
        }

        &:disabled {
            color: var(--pretty-base-color-lighter);
            
            &:hover {
                background-color: var(--pretty-bg-color);
            }
        }
    }

    & .divider {
        width: 1px;
        height: 1.5rem;
        margin: 0.1rem 0.25rem;
        background-color: var(--pretty-input-border-color);
    }

    /* match all classes starting with "ri-" */
    & [class^="ri-"] {
        font-size: 1.2rem;
        transition: var(--pretty-transition);
    }

    @media screen and (max-width: 1024px) {
        & .divider {
            display: none;
        }
    }
}