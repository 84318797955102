.image-modal {
    user-select: none;
    z-index: 10000;
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: var(--pretty-transition);
    animation: flexFadeOut 0.3s ease;

    &.open {
        display: flex;
        animation: fadeIn 0.3s ease;
    }
    
    & .image-modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
    }

    & .image-content-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
            max-width: 95vw;
            max-height: 85vh;
            object-fit: cover;
            border-radius: var(--pretty-border-radius);
        }
    }
    
    & button {
        margin-top: 0.5rem;
    }

    & .image-modal-close {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        cursor: pointer;
        font-size: 2rem;
        font-weight: var(--pretty-font-weight);
        color: var(--pretty-bg-color);
    }
}

@media screen and (max-width: 450px) {
    .image-modal {
        & .image-modal-close {
            top: 0.25rem;
            right: 0.5rem;
        }
    }
}