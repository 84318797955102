.memory-map-loading-container {
    z-index: 9999;
    user-select: none;
    position: fixed;
    top: var(--top-nav-height);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--top-nav-height));
    backdrop-filter: blur(2px);
    display: none;
    animation: flexFadeOut 0.3s ease;

    &.active {
        display: flex;
        animation: fadeIn 0.3s ease;
    }

    & .memory-map-loading-text {
        margin: 0.5rem;
        padding: 0.25rem 0.5rem;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-size: 1rem;
        font-weight: var(--pretty-font-weight-bold);
        border-radius: var(--pretty-border-radius-small);
        background: var(--pretty-bg-color);
        display: flex;
        flex-direction: column;

        & .memory-map-loading-pulse {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2rem;
            width: 4rem;

            & .loading-pulse {
                height: 1.25rem;
            }

            & .loading-pulse::before,
            .loading-pulse::after {
                height: 1.25rem;
            }
        }
    }

}

.memory-map-marker-create {
    z-index: 9;
    position: fixed;
    bottom: 4%;
    right: 4%;
    /* padding: 0.5rem 0.7rem; */
    width: 3.5rem;
    height: 3.5rem;
    /* keep the icon in the center */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--pretty-border-radius);
    text-decoration: none;
    font-size: 2rem;
    font-weight: var(--pretty-font-weight-bold);
    opacity: 0.25;
    background-color: var(--pretty-base-color);
    color: var(--pretty-btn-color);
    transition: var(--pretty-transition);
    cursor: pointer;

    &.active {
        opacity: 1;
        transition: var(--pretty-transition);
    }
}

.memory-map-memory-modal-title {
    user-select: none;
    font-size: 1.75rem;
    font-weight: var(--pretty-font-weight-bold);
    text-align: center;
    width: 32rem;
    border: none;

    &:focus {
        color: var(--pretty-base-color);
        background-color: var(--pretty-bg-color);
        border: none;
        outline: none;
    }
}

.memory-map-memory-modal-content-container {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    & .memory-modal-description {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        text-align: center;
        width: 32rem;
        border: none;
        resize: none;
        /* prevent the textarea from being shrinked to height 0 after overflow */
        flex-shrink: 0;

        &:focus {
            color: var(--pretty-base-color);
            background-color: var(--pretty-bg-color);
            border: none;
            outline: none;
        }
    }

    & .memory-modal-images-container {
        width: 100%;
        padding-right: 0.5rem;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 10rem);
        grid-auto-rows: 10rem;
        gap: 1rem;

        & .memory-modal-image-container {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: var(--pretty-border-radius-small);

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: var(--pretty-border-radius-small);
                cursor: pointer;
            }

            & .memory-modal-image-remove-btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 2rem;
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                font-weight: var(--pretty-font-weight-bold);
                opacity: 0.5;
                color: var(--pretty-btn-color);
                background-color: var(--pretty-error-color);
                border-radius: var(--pretty-border-radius-small);
                transition: var(--pretty-transition);
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }
        }

        & .memory-modal-add-image-btn {
            width: 100%;
            height: 100%;
            border: 2px dashed var(--pretty-input-border-color);
            border-radius: var(--pretty-border-radius-small);
            background-color: var(--pretty-bg-color);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            & i {
                color: var(--pretty-input-border-color);
                font-size: 3rem;
                margin: 0.5rem;
            }

            & input {
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                cursor: pointer;
            }

            & .memory-modal-add-image-loading-container {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
}

.memory-map-memory-modal-toolbar {
    position: absolute;
    bottom: -5rem;
    width: auto;
    height: auto;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: var(--pretty-border-radius);

    & .memory-map-modal-toolbar-btn {
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--pretty-border-radius);
        text-decoration: none;
        font-size: 2rem;
        font-weight: var(--pretty-font-weight-bold);
        opacity: 0.5;
        color: var(--pretty-bg-color);
        transition: var(--pretty-transition);
        cursor: pointer;

        &:hover {
            opacity: 1;
            transition: var(--pretty-transition);
        }

        &.delete {
            background-color: var(--pretty-error-color);
        }

        &.reset {
            background-color: var(--pretty-bg-color-lighter);
        }
    }
}


@media screen and (max-width: 450px) {
    .memory-map-memory-modal-title {
        width: 16rem;
    }

    .memory-map-memory-modal-content-container {
        & .memory-modal-description {
            width: 16rem;
        }

        & .memory-modal-images-container {
            grid-template-columns: repeat(3, 5rem);
            grid-auto-rows: 5rem;
            gap: 0.5rem;
        }
    }
}

.memory-map-container {
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}